import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Breadcrumb,
} from "react-bootstrap";

//custom components
import {
  SliderComponent,
  LearningCard,
  TopHeader,
  SoftSkillCheckIn,
  CompanyCode,
  TipsPopup,
  HelpTip,
} from "../../components";
// redux
import { testAPI_Action, resetAPI_Action } from "../../stores/actions";
import { Images, StringConstants } from "../../config";
import { __redirectScrollTop } from "../../utils/utility";

const LandingPage = (props) => {
  return (
    <>
      <div className="lp-screen landingpgs bg-aliceBlue  wave-section d-flex d-sm-block align-items-center ">
        <Container>
          <Row className="py-3 py-md-5 text-center justify-content-sm-center align-items-center">
            <Col sm={8} md={5} lg={4} className=" px-sm-5 px-md-3 px-xl-5">
              <div className="wrp-logo mt-3">
                <img src={Images.infinity} className="logos mb-4" />
                <h1 className="title mb-4">
                  {StringConstants.Welcome_Lumenai}
                </h1>
              </div>
              <div className="lp-slider">
                <SliderComponent />
              </div>
            </Col>
            <Col md={7} lg={{ span: 5 }}>
              <div className=" d-none d-md-block m-auto ml-md-auto cards-box lp-box text-sm-left lp-card-size inside-space">
                <div className="box-1">
                  <h4 className="small-text mb-3">
                    {StringConstants.letsgo_start}
                  </h4>
                  {/* <p className="notice-text mb-3 d-none d-sm-block">
                    Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do
                    eiusmod tempor.
                  </p> */}
                  <Link
                    to="/register"
                    onClick={__redirectScrollTop}
                    className="btn py-3 px-3 border-0 btn-text text-white btn-gradiant-bg btn-circle-30"
                  >
                    {StringConstants.RegisterNow}
                  </Link>
                </div>
                <div className="box-2 mt-4">
                  <h4 className="small-text mb-3">
                    {" "}
                    {StringConstants.Already_register}
                  </h4>
                  {/* <p className="notice-text mb-3 d-none d-sm-block">
                    Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do
                    eiusmod tempor.
                  </p> */}
                  <Link
                    to="/login"
                    onClick={__redirectScrollTop}
                    className="btn py-3 px-3 border-color-blue  mx-auto border-2 btn-text  btn-circle-30"
                  >
                    {StringConstants.Login}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* footer of wave */}
      <div className="footer-wave landingpgs pb-4 position-relative ">
        <div className="lp-box moblie-size text-center d-md-none position-relative">
          <div className="box-1 pt-5 pt-md-1">
            <h4 className="small-text font-family-ubuntu text-white font-size-15 line-height-20  mb-3">
              {StringConstants.letsgo_start}
            </h4>
            <Link
              to="/register"
              onClick={__redirectScrollTop}
              className="btn py-3 maxbtn-311 mx-auto border-0 btn-text text-white btn-gradiant-bg btn-block btn-circle-30"
            >
              {StringConstants.RegisterNow}
            </Link>
          </div>
          <div className="box-2 mt-4">
            <h4 className="small-text font-family-ubuntu text-white font-size-15 line-height-20 mb-3">
              {" "}
              {StringConstants.Already_register}
            </h4>
            <Link
              to="/login"
              onClick={__redirectScrollTop}
              className="btn py-3 maxbtn-311 border-color-white  mx-auto border-2 btn-text text-white btn-block btn-circle-30"
            >
              {StringConstants.Login}
            </Link>
          </div>
        </div>

        <div className="position-absolute init-top-right d-none d-sm-block">
          {/* <HelpTip /> */}
          <TipsPopup />
        </div>
      </div>
      {/* fotoer wave end */}
    </>
  );
};

export default LandingPage;
