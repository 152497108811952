import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Page400, Page500, InternetConnectivity } from "../../screens";
import { Row, Col } from "react-bootstrap";
// redux
import {
  isLogout,
  hubAPI_Action,
  resetHubAPI_Action,
  profileMeAPI_Action,
  resetAssessmentSummaryAPI_Action,
} from "../../stores/actions";
import { Colors, Images, StringConstants } from "../../config";
import {
  getData,
  setData,
  TOKEN,
  SUMMARY_ATTEMPT,
  USER_DATA,
  clearAllData,
  GROUP_DATA,
} from "../../utils/storage";

import {
  CategoryCard,
  LearningCard,
  NestedPiChart,
  ProgressCount,
  TipsPopup,
  SoftSkillCheckIn,
  NestedPieChartProfile,
  Loader,
  HelpTip,
  GroupCard,
} from "../../components";
import { __redirectScrollTop } from "../../utils/utility";
import { Modal } from "react-bootstrap";

const Hub = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // states
  const [userData, setUserData] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [loader, setLoader] = useState();
  const [displayData, setDisplayData] = useState();
  const [profileCount, setProfileCount] = useState();

  const [questionBankId, setQuestionBankId] = useState();
  const [errorModel, setErrorModel] = useState(false);
  const [errorDataModel, setErrorDataModel] = useState();
  const [progressValue, setProgressValue] = useState();
  const [categoryArray, setCategoryArray] = useState([]);
  const [summaryAttempt, setSummaryAttempt] = useState();
  const [categoryImageArray, setcategoryImageArray] = useState([]);
  const [backgroundColorArraycore, setBackgroundColorArraycore] = useState([]);
  const [backgroundColorArraymanager, setBackgroundColorArraymanager] =
    useState([]);
  const [backgroundColorArraytalent, setBackgroundColorArraytalent] = useState(
    []
  );
  const getUiState = useSelector(state => state.getUiState);


  //selector
  const hubData = useSelector((state) => state.hub);
  const profilemeData = useSelector((state) => state.profileme);

  const [modalShow, setModalShow] = useState(false);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="popup-custom-box"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="popup-content">
            <div className="text-right">
              <button
                className="px-3 py-2 background-transparent border-0"
                onClick={props.onHide}
              >
                <img src={Images.close_skill} />
              </button>
            </div>
            <div className="text-center mt-3 mb-3 px-sm-3">
              <h3 className="px-4 font-size-18 family-font-ubuntu font-weight-bold line-height-22 text-color-violet mb-3">
                Which Space you want to use?
              </h3>
              {
                (userData.redirect_to === "admin-space-setup")
                  ? <Link
                    to="/admin-space-setup"
                    onClick={props.onHide}
                    className="btn mt-3  min-width-245 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30"
                  >
                    {StringConstants.AdminSpace}
                  </Link>
                  : <Link
                    to="/people-manager"
                    onClick={props.onHide}
                    className="btn mt-3  min-width-245 font-size-16 font-weight-bold font-family-ubuntu text-white bg-violet px-5 py-2 line-height-32 btn-circle-30"
                  >
                    {StringConstants.AdminSpace}
                  </Link>
              }
            </div>

            {/* footer of wave */}
            <div className="footer-popup-wave bg-violet text-center pb-5 position-relative">
              {
                (userData.redirect_to === "user-complete-profile")
                  ? <Link
                    to="/user-profile"
                    onClick={props.onHide}
                    className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.AssessmentSpace}
                  </Link>
                  : <Link
                    to="/hub"
                    onClick={props.onHide}
                    className="btn min-width-245 px-4 py-2 mt-2 border-2 border-color-white text-white btn-circle-30 text-center"
                  >
                    {StringConstants.AssessmentSpace}
                  </Link>
              }
            </div>
            {/* fotoer wave end */}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          setBackgroundColorArraymanager([]);
          setBackgroundColorArraycore([]);
          setBackgroundColorArraytalent([]);
          getData(GROUP_DATA).then((groupData) => {
            dispatch(profileMeAPI_Action(res.token, groupData.groupId));
          })

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    // if (getUiState.selectedGroup !== null) {
    //   getData(GROUP_DATA).then((groupdata) => {
    //     if (groupdata !== null) {
    //       setSelectedGroup(groupdata)
    //     }
    //   })
    // }

    if (getUiState.selectedGroup !== null && getUiState.selectedGroup.groupId !== selectedGroup?.groupId) {
      getData(TOKEN).then((res) => {
        getData(GROUP_DATA).then((groupdata) => {
          if (groupdata !== null) {
            setSelectedGroup(groupdata)
            dispatch(hubAPI_Action(res.token, groupdata.groupId));
            dispatch(profileMeAPI_Action(res.token, groupdata.groupId));
          }
        })
      })
    }

  }, [getUiState])

  useEffect(() => {
    if (profilemeData.isFetching) {
      setLoader(true);
    } else if (profilemeData.isSuccess && profilemeData.data !== null) {
      setLoader(false);

      setData(USER_DATA, profilemeData.data.data.userDetails);
      if (profilemeData.data.data.categoryArr !== undefined) {
        let newmanagrArray = [];
        let newcoreArray = [];
        let newtalentArray = [];
        let sortedcatArray = profilemeData.data.data.categoryArr;

        sortedcatArray.sort((a, b) => a.categoryOrder - b.categoryOrder);
        setCategoryArray(sortedcatArray);

        sortedcatArray.map((item, index) => {
          if (item.highlightCategoryCoreSection === true) {
            newcoreArray.push("#48FFFF");
          } else {
            newcoreArray.push("#381474");
          }
          if (item.highlightCategoryManagerialSection === true) {
            newmanagrArray.push("#275EFF");
          } else {
            newmanagrArray.push("#381474");
          }
          if (item.highlightCategoryTalentedSection === true) {
            newtalentArray.push("#F904FF");
          } else {
            newtalentArray.push("#381474");
          }
        });

        setBackgroundColorArraycore(newcoreArray);
        setBackgroundColorArraymanager(newmanagrArray);
        setBackgroundColorArraytalent(newtalentArray);

        // for (let i = 0; i < 8; i++) {
        //   if (i < newmanagrArray.length) {
        //     backgroundColorArraymanager.push(newmanagrArray[i]);
        //     backgroundColorArraycore.push(newcoreArray[i]);
        //     backgroundColorArraytalent.push(newtalentArray[i]);
        //   } else {
        //     backgroundColorArraymanager.push("#381474");
        //     backgroundColorArraycore.push("#381474");
        //     backgroundColorArraytalent.push("#381474");
        //   }
        // }

        let setRecentCount =
          profilemeData &&
          profilemeData.data &&
          profilemeData.data.data &&
          profilemeData.data.data.userDetails &&
          profilemeData.data.data.userDetails.recent_activity_badge_count;

        setProfileCount(setRecentCount);
      }
    } else if (profilemeData.isError) {
      setErrorModel(true);
      setErrorDataModel(profilemeData.data);
    }
  }, [profilemeData.data]);

  useEffect(() => {
    try {
      getData(TOKEN)
        .then((res) => {
          getData(GROUP_DATA).then((groupdata) => {
            if (groupdata !== null) {
              setSelectedGroup(groupdata)
              dispatch(hubAPI_Action(res.token, groupdata.groupId));
            }
          })
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (hubData.isFetching) {
      setLoader(true);
    } else if (hubData.isSuccess && hubData.data !== null) {
      setLoader(false);
      let dashboardData = hubData.data.data;
      setDisplayData(dashboardData);

      if (dashboardData.upComingAssessment.length > 0) {
        setQuestionBankId(dashboardData.upComingAssessment[0].questionBankId);
        setData(
          "quetionBankId",
          dashboardData.upComingAssessment[0].questionBankId
        );
      }
      setData(SUMMARY_ATTEMPT, dashboardData.upComingAssessment.attempt);
      __progressValue();
    } else if (hubData.isError) {
      setErrorModel(true);
      setErrorDataModel(hubData.data);

      // hanlde server response 401
      // console.log("error", hubData.data && hubData.data.error.status_code)
      if (hubData && hubData.data && hubData.data.error.status_code === 401) {
        dispatch(resetHubAPI_Action());
        clearAllData();
        window.location.replace("/");
      }
    }
  }, [hubData]);

  useEffect(() => {
    getData(USER_DATA)
      .then((res) => {
        setUserData(res);


        // if (res.user_invite_status === 2 || res.user_invite_status === 0) {
        if (res.user_invite_status !== 2) {
          // console.log("user invite", res);
        } else {
          // console.log("user invite join comp", res);
          history.push({
            pathname: "/hub",
          });
        }
        getData(SUMMARY_ATTEMPT).then((SUMMARY_ATTEMPT) => {
          // console.log("SUMMARY_ATTEMPT", SUMMARY_ATTEMPT);
          setSummaryAttempt(SUMMARY_ATTEMPT);
        });
      })

      .catch(() => {
        console.log("REDIRECT to LOGIN");
      });
  }, []);

  useEffect(() => {
    console.log('userData hit');
    const userSpacePopUp = localStorage.getItem('userSpacePopUp');
    if (userData?.userRoles.length > 1 && userSpacePopUp === null) {
      setTimeout(() => {
        setModalShow(true);
      }, 3000)
      localStorage.setItem('userSpacePopUp', 1);
    }
  }, [userData])

  const __progressValue = () => {
    // console.log("display",  hubData.data.data)
    let totalCountValue =
      hubData.data.data.pathwayCompleteCount +
      hubData.data.data.pathwayIncompleteCount;
    let totalProgressValue =
      hubData.data.data.pathwayCompleteCount / totalCountValue;
    let finalvalue = Math.floor(totalProgressValue * 100);
    setProgressValue(finalvalue);
  };
  const lumentEmptyChart = () => {
    return (
      <>
        <div className="pie-position1 position-absolute1 overflow-mobile">
          {
            profileCount === 0 ?

              <Link
                to="/hub"
                className="infinite-icon-for-mobile position-relative d-md-none mb-2 pt-2 mt-2 d-block "
              >
                {/* <div className="badges">{profileCount}</div> */}
                <img src={Images.infinity_white} className="lumenai-logo0" />
              </Link>
              :
              <Link
                to="/hub/your-activity"
                className="infinite-icon-for-mobile position-relative d-md-none mb-2 pt-2 mt-2 d-block "
              >
                <div className="badges">{profileCount}</div>
                <img src={Images.infinity_white} className="lumenai-logo0" />
              </Link>


          }


          <div className="block-pie">
            <div className="pr-1 print-name-block1">
              <p className="text-block0 text-trim mb-0 mt-2 font-size-20 font-family-ubuntu font-weight-bold line-height-22 text-color-aliceBlue ">
                {userData && <span>Hello, {userData.first_name}</span>}
              </p>
            </div>

            {/* <div className="text-block-position1 direcation">
              <p className="text-block1 text-trim mb-0 font-size-16 font-family-ubuntu font-weight-bold line-height-22 text-color-aliceBlue ">
                Your Lumen is empty
              </p>
              <p className="text-block2 text-trim mb-0 font-size-13 font-family-ubuntu font-weight-bold line-height-22 text-color-cyan ">
                {StringConstants.Start_training}
              </p>
            </div> */}

          </div>
          <div className="pie-position-block">
            <NestedPiChart chartBlank />
          </div>
        </div>
      </>
    );
  };

  const noDataShowHub = () => {
    return (
      <div className="mt-2">
        <Row className="align-items-center ">
          <Col sm={8} className="pr mb-70">
            <div className="company-cnf-media pb-3">
              <div className="cnf-icon d-sm-flex align-items-start">
                <img
                  src={Images.assessment_icon}
                  className="mr-md-4 mr-sm-3  icon-company-cnf"
                />
                <div className="cnf-media-content mt-3">
                  <h4 className="text-color-violet font-size-18 font-family-ubuntu font-weight-bold line-height-22 mb-2">
                    {StringConstants.complete_assessment}
                    <span> {selectedGroup && selectedGroup?.questionBank.length > 0 ? selectedGroup?.questionBank[0].questionBankName : ''} </span>
                  </h4>
                  <p className="text-color-violet font-size-15 font-family-ubuntu font-weight-medium line-height-22 mb-0">
                    {StringConstants.skill_assessment}
                  </p>

                  <div className="start_assessmet mt-4">
                    {profilemeData &&
                      profilemeData.data &&
                      profilemeData.data.data &&
                      categoryArray.length > 0 ? (
                      <Link
                        // to="/assessment-detail"
                        to="/try-example"
                        onClick={__redirectScrollTop}
                        className="btn btn-text bg-violet text-white py-3 px-4 join-btn btn-circle-30"
                      >
                        {StringConstants.start_assessment}
                      </Link>
                    ) : (
                      <Link
                        // to="/initial-soft-skill"
                        to="/try-example"
                        onClick={__redirectScrollTop}
                        className="btn btn-text bg-violet text-white py-3 px-4 join-btn btn-circle-30"
                      >
                        {StringConstants.start_assessment}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const profileChart = () => {
    return (
      <>
        <div className="pie-position1 position-absolute1 overflow-mobile">
          <Link
            to="/hub/your-activity"
            className="infinite-icon-for-mobile position-relative d-md-none mb-2 pt-2 "
          >
            {/* <div className="badges"> */}
            {profileCount > 0 ? (
              <div className="badges">{profileCount}</div>
            ) : null}
            {/* </div> */}
            <img src={Images.infinity_white} className="lumenai-logo0" />
          </Link>
          <div className="block-pie">
            <div className="pr-1 print-name-block1">
              <p className="text-block0 text-trim mb-0 mt-2 font-size-20 font-family-ubuntu font-weight-bold line-height-22 text-color-aliceBlue ">
                {userData && (
                  <span>
                    {StringConstants.Hello} {userData.first_name}
                  </span>
                )}
              </p>
            </div>

            {displayData && displayData.incompleteTraining.length !== 0 ? (
              <div className="text-block-position1 direcation">
                {displayData && displayData.incompleteTraining !== null ? (
                  <p className="text-block1 text-trim mb-0 font-size-16 font-family-ubuntu font-weight-bold line-height-22 text-color-aliceBlue ">
                    {displayData && displayData.incompleteTraining.categoryName}
                    {/* {StringConstants.Emotional_judgement} */}
                  </p>
                ) : null}
                <p className="text-block2 text-trim mb-0 font-size-13 font-family-ubuntu font-weight-bold line-height-22 text-color-cyan ">
                  {StringConstants.Start_training}
                </p>
              </div>
            ) : null}
          </div>
          <div className="pie-position-block">
            {profilemeData.data && categoryArray ? (
              <NestedPieChartProfile
                backgroundColorArraycore={backgroundColorArraycore}
                backgroundColorArraymanager={backgroundColorArraymanager}
                backgroundColorArraytalent={backgroundColorArraytalent}
                categoryImageArray={categoryImageArray}
                catgoryArray={
                  profilemeData.data && categoryArray
                }
              />
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const recentTraining = () => {
    return (
      <div className="recent-training  ">
        <p className="font-size-16 font-family-ubuntu width-50 font-weight-medium line-height-22 text-color-aliceBlue mb-3">
          <span>
            {" "}
            {StringConstants.Recent_training}
            {""} {StringConstants.dev}
          </span>
        </p>
        <div className="grid-box recent-training-block d-flex flex-wrap">
          {
            // let newRecentTraining = displayData.recentTraining.slice(1,2)
            displayData &&
            displayData.recentTraining.slice(0, 2).map((item, index) => {
              let recentIcon = "";
              let recentColor = "";

              switch (item.categoryName) {
                case "Problem Solving":
                  recentIcon = Images.probsolving_whitecard;
                  break;
                case "Critical Thinking":
                  recentIcon = Images.criticalthinking_whitecard;
                  break;
                case "Teamwork":
                  recentIcon = Images.teamwork_whitecard;
                  break;
                case "Communication":
                  recentIcon = Images.communication_whitecard;
                  break;
                case "Leadership":
                  recentIcon = Images.leadership;
                  break;
                case "Time Management":
                  recentIcon = Images.timemgt_whitecard;
                  break;
                case "Emotional Judgement":
                  recentIcon = Images.emotionaljudge;
                  break;
                case "Creativity":
                  recentIcon = Images.creativity;
                  break;
                case "Flexibility/Adaptability":
                  recentIcon = Images.Flexibilty_active;
                  break;

                default:
                  recentIcon = Images.infinity;

              }

              return (
                <div key={index}>
                  <CategoryCard
                    // color={Colors.white}
                    // backgroundColor={recentColor}
                    backgroundColor={"#ffffff"}
                    color={"#5b5b5b"}
                    cardIcon={recentIcon}
                    categoryName={item.categoryName}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  // console.log(
  //   "displayData.upComingAssessment.attempt > 3",
  //   displayData && displayData.upComingAssessment.attempt > 3
  // );

  const softskill = () => {
    return (
      <div
        className={
          displayData && displayData.recentTraining.length === 0 ? "mt-5 pt-5 space" : "no-space"
        }
      >
        <div className="soft-skill-upcoming mb-4">
          <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22 text-color-black mb-3">
            {StringConstants.Upcoming}
          </p>
          <div
            className={
              (displayData && displayData.upComingAssessment.attempt > 3) ||
                (displayData && displayData.pathwayIncompleteCount !== 0) ||
                (displayData && displayData.upComingAssessment.re_attempt === 0)
                ? "disable"
                : "enable"
            }
          >
            <Link
              // to={"/questions/" + questionBankId}
              // to="/assessment-detail"
              to="/try-example"
              onClick={__redirectScrollTop}
              className="d-md-inline-block opcity-block-box"
            >
              {displayData && (
                <>
                  <SoftSkillCheckIn
                    title={
                      displayData.upComingAssessment.length !== 0
                        ? displayData.upComingAssessment.questionBankName
                        : userData && userData.questionBankName[0]
                    }
                    subtitle={
                      displayData.upComingAssessment
                        .assessment_successfully_completed === 1
                        ? "You have successfully completed this lumen."
                        : displayData.pathwayIncompleteCount !== 0
                          ? "Complete your pathways to retry the Lumen"
                          : displayData.upComingAssessment.attempt <= 3
                            ? "Ready to Start"
                            : "You have completed your Lumen"

                      // displayData.upComingAssessment
                      //   .assessment_successfully_completed === 1 &&
                      // displayData.pathwayIncompleteCount === 0
                      //   ? "You have successfully completed this lumen."
                      //   : "Complete your pathways Or wait for 24hrs" ||
                      //     displayData.upComingAssessment.attempt > 3
                      //   ? " Ready to Start "
                      //   : "You have allow only 3 Attempt "

                      //   displayData.upComingAssessment.attempt > 3
                      // ? " Ready to Start "
                      // : "You have allow only 3 Attempt"
                    }
                  />
                </>
              )}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const yourPath = () => {
    return (
      <div className="your_pathway mb-4 width-70">
        <div className="d-flex justify-content-between align-items-center progress-block">
          <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22 text-color-black mb-3">
            {StringConstants.Your_Pathway}
          </p>
          <Link
            to="/pathway"
            onClick={__redirectScrollTop}
            className="viewall-w font-size-13 font-family-ubuntu font-weight-regular line-height-18 text-color-black mb-2"
          >
            {StringConstants.view_all}
          </Link>
        </div>

        <div className="progress-path w-100">
          <div className="progress custom-progress-style">
            <div
              className="progress-bar bg-Electric-Purple"
              style={{ borderRadius: "3px", width: `${progressValue}%` }}
              role="progressbar"
              aria-valuenow={progressValue}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>

        <div className="progress-status grid-2box flex-wrap w-100 mt-4">
          {displayData && (
            <>
              <ProgressCount
                status="complete"
                count={displayData.pathwayCompleteCount}
                statusName={StringConstants.Done}
              />
              <ProgressCount
                count={displayData.pathwayIncompleteCount}
                statusName={StringConstants.left}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const activeLearning = () => {
    return (
      <>

        <div className="active-learning-box learning-box mb-70">
          <div className="d-flex justify-content-between align-items-center width-48">
            <p className="font-size-16 font-family-ubuntu font-weight-medium line-height-22 text-color-black mb-3">
              {StringConstants.Active_learning}
            </p>
            <Link
              to="/hub/activity-learning"
              onClick={__redirectScrollTop}
              className="font-size-13 font-family-ubuntu font-weight-regular line-height-18 text-color-black mb-2"
            >
              {StringConstants.view_all}
            </Link>
          </div>
          <div className="active-block pb-5">
            {displayData &&
              displayData.activeLearning.map((item, index) => {
                let activeLearningIcon = "";
                switch (item.pathwayCategories[0]) {
                  case "Problem Solving":
                    activeLearningIcon = Images.probsolving_whitecard;
                    break;
                  case "Critical Thinking":
                    activeLearningIcon = Images.criticalthinking_whitecard;
                    break;
                  case "Teamwork":
                    activeLearningIcon = Images.teamwork_whitecard;
                    break;
                  case "Communication":
                    activeLearningIcon = Images.communication_whitecard;
                    break;
                  case "Leadership":
                    activeLearningIcon = Images.leadership;
                    break;
                  case "Time Management":
                    activeLearningIcon = Images.timemgt_whitecard;
                    break;
                  case "Emotional Judgement":
                    activeLearningIcon = Images.emotionaljudge;
                    break;
                  case "Creativity":
                    activeLearningIcon = Images.creativity;
                    break;

                  default:
                    activeLearningIcon = Images.emotionaljudge;
                }

                return (
                  item.userPathwayStatus === 0 ?

                    <LearningCard
                      data={item}
                      keyvalue={index}
                      status={item.userPathwayStatus}
                      icon={activeLearningIcon}
                      title={item.pathwayTitle}
                      subtitle={item.pathwayCategories[0]}
                    />
                    : null
                );
              })}
          </div>
        </div>

      </>
    );
  };

  return (
    <>
      {!navigator.onLine && <InternetConnectivity />}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 500 &&
        window.location.replace("/error-500")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 404 &&
        window.location.replace("/error-400")}
      {errorDataModel &&
        errorDataModel.error &&
        errorDataModel.error.status_code === 401 &&
        window.location.replace("/")}

      {loader === true && <Loader />}

      <div className="hub-screen bg-aliceBlue before-curve headerFooter-minusHeight">
        <div className="container mobile-wrp-100">
          <div className="row justify-content-md-center">
            <div className="col-md-11 col-lg-9 col-xl-8 pr-0">
              {categoryArray.length > 0
                ? profileChart()
                : lumentEmptyChart()}
            </div>
          </div>

          {
            // console.log("activeLearning--12", displayData.activeLearning.length > 0)
            //  displayData && displayData.activeLearning.length > 0 ? (
            (displayData && displayData.pathwayIncompleteCount !== 0 || displayData && displayData.pathwayCompleteCount !== 0) ||
              (displayData &&
                displayData.upComingAssessment
                  .assessment_successfully_completed !== 0) ? (
              <>
                <div className="row justify-content-md-center topspace-pie">
                  <div className="col-md-11 col-lg-9 col-xl-8 mt-0">
                    {displayData && displayData.recentTraining.length > 0
                      ? recentTraining()
                      : null}

                    <div
                      className={
                        (displayData &&
                          displayData.upComingAssessment
                            .assessment_successfully_completed === 1) ||
                          (displayData &&
                            displayData.upComingAssessment.re_attempt === 1)
                          ? "enable"
                          // displayData && displayData.activeLearning.length > 0
                          //   ? "enable"
                          //   : "enable"
                          : "disable"

                          && (displayData && displayData.recentTraining.length === 0 ? "mt-1 pt-1 not_available" : "recent_available")
                      }
                    >
                      {softskill()}
                    </div>

                    {
                      // displayData && displayData.activeLearning.length > 0  ? (
                      <>
                        {yourPath()}
                        {displayData && displayData.activeLearning.length > 0 ? (
                          activeLearning()
                        ) : null}
                      </>
                      // ) : null
                    }
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row justify-content-md-center blank-data">
                  <div className="col-md-11 col-lg-9 col-xl-8">
                    {noDataShowHub()}
                  </div>
                </div>
              </>
            )
          }
        </div>
        <div className=" position-fixed lumen-help fixed-lumen-help">
          <HelpTip />
        </div>
      </div>



      {modalShow ?
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        : null}

    </>
  );
};

export default Hub;
