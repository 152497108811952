import React from "react";

const TopPopup = (props) => {
  return (
    <>
      <div className="bg-violet py-4 popup-wave">
        <div className="container ">
          <h4 className="  text-color-aliceBlue line-height-24 font-size-20 font-family-ubuntu font-weight-bold mb-2">
            {props.heading}
          </h4>

          <p className=" text-left text-white wx-734 line-height-24 font-size-15 font-family-ubuntu font-weight-regular mb-2">
            {props.content}
          </p>
        </div>
      </div>
    </>
  );
};
export default TopPopup;
